import React from "react"

const Icon = (props) => {
  return(
    <div
      id="menu"
      className={props.className}
      >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default Icon
