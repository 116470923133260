import React from "react"
// import { rhythm } from "../../utils/typography"


const Heading = (props) => {
  return(
    <props.tagName className={props.size}>
      {props.children}
    </props.tagName>
  );
}

export default Heading
