/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/author.png/" }) {
        childImageSharp {
          fixed(width: 120, height: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  const author = data.site.siteMetadata.author

  return (
    <section style={{marginBottom: rhythm(2.5)}} className="text-center mt-12" >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          minWidth: 100,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
        />
      <p className="mt-2 font-bold text-xl text-color-silver-pink">{author}</p>
      <p className="text-sm">
        JAMstacなブログにまつわる、技術的なことなどを記録しています。
      </p>
    </section>
  )
}

export default Bio
