import React from "react"
import {useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return(
    <footer className="mt-20 mb-6 text-sm text-color-silver-pink text-center">
      &copy;{data.site.siteMetadata.title} {new Date().getFullYear()}
    </footer>
  );
}

export default Footer
