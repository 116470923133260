import React from "react"
import {Link, useStaticQuery, graphql } from "gatsby"

const Header = ({location}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const rootPath = `${__PATH_PREFIX__}/`
  let size

  if (location.pathname === rootPath) {
    size = "text-6xl my-20"
  } else {
    size = "text-xl my-4"
  }

  return (
    <div className={`text-center text-bold text-color-silver-pink ${size}`}>
      <Link to={`/`}> {data.site.siteMetadata.title} </Link>
    </div>
  )
}

export default Header
