import React, { useState, useEffect } from "react"
import axios from "axios"

const Giphy = (query="cat") => {
  const [uri, setUri] = useState(0);
  const [title, setTitle] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      axios({
        "method":"GET",
        "url":"https://giphy.p.rapidapi.com/v1/gifs/search",
        "headers":{
          "content-type":"application/octet-stream",
          "x-rapidapi-host":"giphy.p.rapidapi.com",
          "x-rapidapi-key":"858f2ccb5fmshb6c6dcff83b16eep19f94ejsne61a6f0df075"
        },"params":{
          "limit":"1",
          "q": query,
          "rating": "g",
          "random_id": "e826c9fc5c929e0d6c6d423841a282aa",
          "api_key":"Bf8eXkcnNBincf7bU1z6K4pex0yxTnVs"
        }
      })
      .then((response)=>{
        // console.log(response.data)
        const data = response.data.data

        const img = data.map(o => o.images.downsized_small.mp4)
        setUri(img)

        const title = data.map(o => o.title)
        setTitle(title)
      })
      .catch((error)=>{
        console.log(error)
      })
    }

    fetchData()
  }, [query]);


  return(
    <img src={uri} alt={title} />
  )
}

export default Giphy
