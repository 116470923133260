import React from "react"
import { Link } from "gatsby"
import Giphy from "../atoms/giphy"
import Heading from "../atoms/heading"
import TagButtons from "./tagButtons"
import Style from "./card.module.css"

const Card = (props) => {
  const frontmatter = props.node.frontmatter
  const slug = props.node.fields.slug

  const slugOfDate = () => {
    const regex = /-/gi
    return props.node.fields.slug.substring(1,11).replace(regex,'/')
  }

  return(
    <div className={`${Style.card} p-4 frame flex flex-col justify-between leading-normal`}>
      <Link to={slug}>
        <div className="mb-4 p-4 frame">
          <Heading tagName="h2" size="font-bold text-xl mb-2">{ frontmatter.title }</Heading>
        </div>

        <div className="mb-4 frame bg-black h-40 flex justify-center lg:h-auto overflow-hidden">
          <Giphy query={frontmatter.giphy ? frontmatter.giphy : frontmatter.tag.join()} />
        </div>

        <div className="mb-4 p-4 frame">
          <p className="text-base">{ frontmatter.digest }</p>
        </div>
      </Link>

      <div className="mb-4 flex flex-wrap items-center text-sm w-full leading-none">
        <TagButtons tags={frontmatter.tag} />
      </div>

      <p className="py-1 frame text-sm leading-none text-center">
        {slugOfDate()}
      </p>
    </div>
  )
}

export default Card
