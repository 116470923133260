import React, {useState} from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Icon from "../moleculs/icon"
import Style from "../moleculs/icon.module.css"
import { Transition } from 'react-transition-group';

const Nav = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        categories: allMarkdownRemark {
          group(field: frontmatter___category) {
            fieldValue
          }
        }
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `
  )

  const catList = data.categories.group.map(item  => (
      <li key={ item.fieldValue } className="py-4">
        <Link to={`/${ item.fieldValue }`}>
          { item.fieldValue }
        </Link>
      </li>
    )
  )

  const [showNavi, setShowNavi] = useState(false);
  const toggle = (show) => {
    return !show
  }

  const duration = 200;
  const defaultStyle = {
    transition: `all ${duration}ms ease-in-out`,
    transform: `translateX(-100%)`,
  }

  const transitionStyles = {
    entering: { transform: `translateX(0px)` },
    entered:  { transform: `translateX(0px)` },
    exiting:  { transform: `translateX(-100%)` },
    exited:  { transform: `translateX(-100%)` },
  };
  return (
    <>
      <Transition
        in={showNavi}
        timeout={400}
        >
       {state => (
         <nav
           className="fixed h-screen z-10 inset-0 pt-16 pl-4 text-bold text-2xl sm:w-full md:w-1/3 lg:w-1/4"
           style={{
             ...defaultStyle,
             ...transitionStyles[state]
           }}
           >
           <Link to="/">
             <div className="mb-10 text-lg">
               {data.site.siteMetadata.title}
             </div>
           </Link>
           <ul className="capitalize">
             { catList }
           </ul>
         </nav>
       )}
      </Transition>

      <button className="fixed left-0 top-0 ml-2 mt-8 z-10" onClick={ () => setShowNavi(toggle(showNavi)) }>
        <Icon
          className={ `${Style.menu} ${showNavi ?  Style.cross : ''}` }
          />
      </button>
    </>
  )
}
export default Nav
