import React from "react"
import Card from "../moleculs/card"

const GridCards = ({edges}) => {
  return(
    <>
      {edges.map(({ node }) => {
        return (
          <div key={node.fields.slug} className="mb-8">
            <Card node={node} />
          </div>
        )
      })}
    </>
  )
}

export default GridCards
