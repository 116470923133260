import React from "react"
import { Link } from "gatsby"
import Btn from "../atoms/button"

const TagButtons = (props) => {
  return(
    <>
    {
      props.tags.map((tag,index) => {
        return(
          <Link key={index} className="mx-1" to={ `/${tag}` }>
            <Btn class="text-xs bg-transparent py-2 px-4">{ `#${tag}` }</Btn>
          </Link>
        )
      })
    }
    </>
  )
}

export default TagButtons
