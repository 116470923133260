/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({lang, meta, title, digest, category, tag, img, slug, birthTime, modifiedTime}) => {
  const data = useStaticQuery(
    graphql`
      query {
        avatar: file(absolutePath: { regex: "/author.png/" }) {
          childImageSharp {
            fixed(width: 120, height: 120) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        site {
          siteMetadata {
            siteUrl
            title
            description
            keyword
            author
          }
        }
      }
    `
  )

  // const {title, digest, category, tag, img} = entry.frontmatter
  // const {slug} = entry.fields
  // const {birthTime, modifiedTime} = entry.parent

  const avatar = data.avatar.childImageSharp.fixed
  const heroImg = img ? img : avatar
  const pageUrl = slug ? data.site.siteMetadata.siteUrl + slug : data.site.siteMetadata.siteUrl

  const schemeOrg = [
    {
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": pageUrl
      },
      "headline": digest,
      "image": {
        "@type": "ImageObject",
        "url": heroImg,
        "width": 1200,
        "height": 1200
      },
      "datePublished": birthTime,
      "dateModified": modifiedTime,
      "author": {
        "@type": "Person",
        "name": data.site.siteMetadata.title
      },
      "publisher": {
        "@type": "Organization",
        "name": data.site.siteMetadata.author,
        "logo": {
          "@type": "ImageObject",
          "url": avatar,
          "width": 120
        }
      },
      "description": digest
    },
    {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "item": {
            "@id": pageUrl,
            "name": category
          }
        }
      ]
    }
  ]


  return (
    <Helmet
      htmlAttributes={{lang}}
      title={title ? title : data.site.siteMetadata.title}
      // titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      script={[
        {type:"application/ld+json", innerHTML:(JSON.stringify(schemeOrg))}
      ]}
      meta={[
        {name: `viewport`,　content:`width=device-width,minimum-scale=1,initial-scale=1`},
        {name: `theme-color`,　content:`#ffffff`},
        // General tags
        {name: `keywords`,content: tag ? tag : data.site.siteMetadata.keyword},
        {name: `description`,content: digest ? digest : data.site.siteMetadata.description},
        // OpenGraph tags
        {property: `og:type`,content: `website`},
        {property: `og:site_name`,content: data.site.siteMetadata.title},
        {property: `og:title`,content: title ? title : data.site.siteMetadata.title},
        {property: `og:description`,content: digest ? digest : data.site.siteMetadata.description},
        {property: `og:url`,content: pageUrl},
        // {
        //   name: `article:author`,
        //   property: `article:author`,
        //   content: `https://www.facebook.com/chaosboy/`,
        // },
        // {
        //   name: `twitter:site`,
        //   content: `@chaosboy_tokyo`,
        // },
        // {
        //   name: `twitter:card`,
        //   content: `summary`,
        // },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
        // {
        //   name: `twitter:title`,
        //   content: title,
        // },
        // {
        //   name: `twitter:description`,
        //   content: metaDescription,
        // },
      ].concat(meta)}
      >
      <script data-ad-client="ca-pub-5626582411941306" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
